import {createSettingsParams, SettingsParamType} from '@wix/tpa-settings'

export type TextsSettingsParams = {
  titleText: SettingsParamType.String
  titleRequestText: SettingsParamType.String
  buttonLabelText: SettingsParamType.String
  buttonLabelRequestText: SettingsParamType.String
}

export const textsSettingsParams = createSettingsParams<TextsSettingsParams>({
  titleText: {
    type: SettingsParamType.String,
    getDefaultValue: ({t}) => t('uou-reservations.reservation-details.title'),
  },
  titleRequestText: {
    type: SettingsParamType.String,
    getDefaultValue: ({t}) => t('uou-reservations.reservation-details.title.request'),
  },
  buttonLabelText: {
    type: SettingsParamType.String,
    getDefaultValue: ({t}) => t('uou-reservations.reservation-details.complete.cta'),
  },
  buttonLabelRequestText: {
    type: SettingsParamType.String,
    getDefaultValue: ({t}) => t('uou-reservations.reservation-details.complete.cta.request'),
  },
})
