import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {Reservation, Status} from '@wix/ambassador-table-reservations-v1-reservation/types'
import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/types'

import {reservationLocationsService} from '../../services/reservationLocationsService'
import {reservationsService} from '../../services/reservationsService'
import {goToNewReservation} from '../../utils/navigation'
import {noop} from '../../utils/helpers'
import {getRegionalSettings} from '../../utils/flowAPI'
import {RequestStatus, wrapRequest} from '../../utils/wrapRequest'
import {createStorageProvider} from '../../utils/storageContext'
import {getReservationLocationsMock} from '../../editor/editorMocks/getReservationLocationsMock'
import {
  getReservationMock,
  reserveReservationMock,
} from '../../editor/editorMocks/getReservationMock'
import {
  validateReservationDataQueryParam,
  validateReservationIdQueryParam,
} from '../../utils/queryParams'

import {reserveReservation, ReserveReservationApiError} from './actions/reserveReservation'
import {createReservation} from './actions/createReservation'

interface ReservationDetailsData {
  getReservationLocationsStatus: RequestStatus
  getReservationStatus: RequestStatus
  submitReservationStatus: RequestStatus
  deleteReservationStatus: RequestStatus
  apiRequestErrorDetails?: ReserveReservationApiError
  regionalSettings?: string
  reservationIdQueryParam?: string
  reservationDataQueryParams?: {
    startDate?: string
    partySize?: string
    reservationLocationId?: string
  }
  isReservationDataQueryParamsValid: boolean
  isReservationIdQueryParamValid: boolean
  isManualApproval: boolean
  reservation?: Reservation
  reservationLocations: ReservationLocation[]
  fitToContentHeight: boolean
}

interface ReservationDetailsActions {
  getReservationLocations: () => Promise<ReservationLocation[] | undefined>
  getReservation: (id: string) => Promise<Reservation | undefined>
  createReservation: ReturnType<typeof createReservation>
  reserveReservation: ReturnType<typeof reserveReservation>
  deleteReservation: (param: {reservationId: string; revision: string}) => Promise<void>
  changeReservation: (reservation?: Reservation) => void
  goToNewReservation: (reservation?: Reservation) => void
}

export interface ReservationDetailsStorage
  extends ReservationDetailsData,
    ReservationDetailsActions {}

export const defaultReservationDetailsStorage: ReservationDetailsStorage = {
  getReservationLocationsStatus: RequestStatus.DEFAULT,
  getReservationStatus: RequestStatus.DEFAULT,
  submitReservationStatus: RequestStatus.DEFAULT,
  deleteReservationStatus: RequestStatus.DEFAULT,
  reservationLocations: [],
  getReservationLocations: noop,
  isReservationIdQueryParamValid: false,
  isReservationDataQueryParamsValid: false,
  isManualApproval: false,
  getReservation: noop,
  reserveReservation: noop,
  createReservation: noop,
  deleteReservation: noop,
  changeReservation: noop,
  goToNewReservation: noop,
  fitToContentHeight: true,
}

const {withStorageProvider, useStorage} = createStorageProvider(defaultReservationDetailsStorage)

const initReservationDetailsStorage = (flowAPI: ControllerFlowAPI): ReservationDetailsStorage => {
  const query = flowAPI.controllerConfig?.wixCodeApi?.location?.query ?? {}

  const isReservationDataQueryParamsValid = validateReservationDataQueryParam(query)
  const isReservationIdQueryParamValid = validateReservationIdQueryParam(query)
  const isManualApproval = isReservationDataQueryParamsValid

  const storage: ReservationDetailsStorage = {
    ...defaultReservationDetailsStorage,
    isManualApproval,
    reservationIdQueryParam: query.reservationId,
    reservationDataQueryParams: {
      startDate: query.startDate,
      partySize: query.partySize,
      reservationLocationId: query.reservationLocationId,
    },
    isReservationDataQueryParamsValid,
    isReservationIdQueryParamValid,
    regionalSettings: getRegionalSettings(flowAPI),
    getReservationLocations: wrapRequest(
      flowAPI,
      reservationLocationsService.getReservationLocations,
      'reservationLocations',
      'getReservationLocationsStatus',
    ),
    getReservation: wrapRequest(
      flowAPI,
      reservationsService.getReservation,
      'reservation',
      'getReservationStatus',
    ),
    reserveReservation: reserveReservation(flowAPI),
    createReservation: createReservation(flowAPI),
    deleteReservation: wrapRequest(
      flowAPI,
      reservationsService.deleteReservation,
      'reservation',
      'deleteReservationStatus',
    ),
    goToNewReservation: (reservation) =>
      goToNewReservation({flowAPI, reservationData: reservation?.details}),
    changeReservation: async (reservation) => {
      if (reservation?.id && reservation.revision) {
        try {
          await storage.deleteReservation({
            reservationId: reservation.id,
            revision: reservation.revision,
          })
        } catch (e) {}
      }
      await storage.goToNewReservation(reservation)
    },
  }

  return storage
}

const mockReservationDetailsStorage = async (
  flowAPI: ControllerFlowAPI,
): Promise<ReservationDetailsStorage> => {
  const reservationLocations = await getReservationLocationsMock(flowAPI)
  const reservation = getReservationMock(flowAPI, Status.HELD, reservationLocations[0].id!)

  return {
    ...defaultReservationDetailsStorage,
    reservationLocations,
    regionalSettings: getRegionalSettings(flowAPI),
    getReservationLocationsStatus: RequestStatus.RESOLVED,
    getReservationStatus: RequestStatus.RESOLVED,
    reservationIdQueryParam: reservation.id!,
    isManualApproval: false,
    isReservationDataQueryParamsValid: false,
    isReservationIdQueryParamValid: true,
    reservation,
    goToNewReservation: () => {
      goToNewReservation({flowAPI})
    },
    changeReservation: () => {
      goToNewReservation({flowAPI})
    },
    reserveReservation: reserveReservationMock(flowAPI),
  }
}

export {
  initReservationDetailsStorage,
  mockReservationDetailsStorage,
  withStorageProvider as withReservationDetailsStorageProvider,
  useStorage as useReservationDetailsStorage,
}
