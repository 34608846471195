import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {Reservation, Reservee} from '@wix/ambassador-table-reservations-v1-reservation/types'
import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/types'

import {reservationsService} from '../../../services/reservationsService'
import {ApiError, isWixHttpError} from '../../../utils/errors'
import {RequestStatus} from '../../../utils/wrapRequest'
import {goToReservationConfirmation} from '../../../utils/navigation'
import {getLogger} from '../../../utils/getLogger'

export enum ReserveReservationApplicationErrorCode {
  HELD_EXPIRED = 'HELD_EXPIRED',
}

export interface ReserveReservationApiError
  extends ApiError<ReserveReservationApplicationErrorCode> {}

export const reserveReservation = (flowAPI: ControllerFlowAPI) => {
  return async (
    reservation: Reservation,
    reservee: Reservee,
    reservationLocation: ReservationLocation,
  ) => {
    const setProps = flowAPI.controllerConfig.setProps
    const logger = getLogger(flowAPI.bi!)

    try {
      setProps({submitReservationStatus: RequestStatus.LOADING})
      const finishedReservation = await reservationsService.reserveReservation(
        flowAPI,
        reservation.id ?? '',
        reservee,
        reservation.revision ?? '',
      )

      // this is the only place where can get just created reservation
      if (finishedReservation?.id) {
        logger.reservationCreated({
          isPreview: false,
          reservation: finishedReservation!,
          reservee,
          reservationLocation,
        })

        await goToReservationConfirmation(flowAPI, finishedReservation.id)
      } else {
        throw new Error('No reservation')
      }
    } catch (err: any) {
      const apiError: ReserveReservationApiError = {}

      if (isWixHttpError(err)) {
        apiError.applicationCode = err?.response?.data?.details?.applicationError
          ?.code as ReserveReservationApplicationErrorCode
        apiError.message = err?.response?.data?.message
      } else {
        apiError.message = err?.message
      }

      setProps({
        submitReservationStatus: RequestStatus.FAILED,
        apiRequestErrorDetails: apiError,
      })
    }
  }
}
