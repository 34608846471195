import {useCallback} from 'react'
import {utcToZonedTime} from '@wix/table-reservations-lib/timezone'

import {useReservationDetailsStorage} from '../../storage'
import {getReservationLocationById} from '../../../../utils/reservationLocation'

export const useHooks = () => {
  const {
    reservationLocations,
    reservation,
    changeReservation,
    regionalSettings,
    isManualApproval,
    reservationDataQueryParams,
  } = useReservationDetailsStorage()

  const currentReservationLocation = getReservationLocationById(
    (isManualApproval
      ? reservationDataQueryParams?.reservationLocationId
      : reservation?.details?.reservationLocationId) ?? '',
    reservationLocations,
  )
  const timeZone = currentReservationLocation?.location?.timeZone

  const handleChangeReservationClick = useCallback(() => {
    changeReservation(
      isManualApproval
        ? {
            details: {
              startDate: new Date(reservationDataQueryParams?.startDate!),
              partySize: Number(reservationDataQueryParams?.partySize),
              reservationLocationId: reservationDataQueryParams?.reservationLocationId,
            },
          }
        : reservation,
    )
  }, [reservation, changeReservation])

  const startDate = utcToZonedTime(
    isManualApproval ? reservationDataQueryParams?.startDate! : reservation?.details?.startDate!,
    timeZone,
  )

  const partySize = isManualApproval
    ? reservationDataQueryParams?.partySize
    : reservation?.details?.partySize

  return {
    isManualApproval,
    startDate,
    partySize,
    regionalSettings,
    locationName: currentReservationLocation?.location?.name,
    handleChangeReservationClick,
  }
}
